// eslint-disable-next-line import/no-named-as-default
import gql from 'graphql-tag'
import { CLUB_FRAGMENT } from './club'
import { PRICE_FRAGMENT } from './price'
import { COMPACT_PROFILE_FRAGMENT } from './profile'
import { PICTURE_FRAGMENT } from './pictures'

export const SHARED_ACTIVITY_FIELDS = `
  id
  title
  location
  signupClose
  startDate
  endDate
  maxSignups
  cancelled
  signupEnabled
  participating
  read
  description
  club {
    ...club
  }
  price {
    ...price
  }
  responsible {
    ...compactProfile
  }
  coverImage {
    ...picture
  }
`

export const ACTIVITY_FRAGMENT = gql`
  ${CLUB_FRAGMENT}
  ${PRICE_FRAGMENT}
  ${COMPACT_PROFILE_FRAGMENT}
  ${PICTURE_FRAGMENT}
  fragment activity on Activity {
    ... on SingleActivity {
      ${SHARED_ACTIVITY_FIELDS}
    }
    ... on RepeatingActivity {
      repeatRule: repeat {
        rrule
        rruleText
        description
      }
      ${SHARED_ACTIVITY_FIELDS}
    }
  }
`

// eslint-disable-next-line import/no-named-as-default
import gql from 'graphql-tag'

export const PICTURE_FRAGMENT = gql`
  fragment picture on Picture {
    id
    description
    source
  }
`
